import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime, Subject } from 'rxjs';
import { BackendService } from 'src/app/services/backend.service';

@Component({
	selector: 'app-landing-search',
	templateUrl: './landing-search.component.html',
	styleUrl: './landing-search.component.scss',
	encapsulation: ViewEncapsulation.None
})
export class LandingSearchComponent implements OnInit {
	searchInput$ = new Subject<string>();
	isFocused
	public searchBoxFocused: boolean = false;
	public loading: boolean = false;
	private engine: string = 'combinedsearch'
	public searchValue: string = ''
	public displaySelectDropdown: boolean = false;
	public isOpen: boolean = false;
	private globalSearchTerm: string = '';

	public searchString: string = 'Search';
	searchPageCurrent: number = 1
	searchResultsTotal: number = 0
	searchSize: number = 10
	searchResults: any[] = []
	error
	constructor(
		private backendService: BackendService,
		private router: Router
	) {

	}

	ngOnInit() {
		this.searchInput$.pipe(debounceTime(500)).subscribe(async (searchInput) => {
			this.searchValue = searchInput
			this.searchResultsTotal = 0
			this.searchResults = []
			if (searchInput && searchInput.length > 2) {
				this.triggerSearch(this.searchValue, 0)
			}
		});
	}

	clickItem(searchQuery, item) {
		const type = item.type.raw
		const itemId = item.id.raw
		const communityId = item.communityid.raw
		this.click(searchQuery, itemId)
		switch (type) {
			case 'event':
				return this.router.navigateByUrl(`/communities/${communityId}/events?id=${itemId}`)
			case 'community':
				return this.router.navigateByUrl(`/communities/${communityId}`)
			default:
				return
		}
	}

	delay(ms: number) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	onSearchFocus($event) {
		this.isFocused = $event;
		this.isOpen = $event === true && this.globalSearchTerm.length > 0;
		this.searchString = $event === true || this.globalSearchTerm.length > 0 ? '' : 'Search';
		this.searchBoxFocused = $event;
		if (this.isFocused) {
			this.isOpen = true;
		}
	}

	click(searchQuery: string, resultId: string): void {
		this.backendService.clickResult({
			query: searchQuery,
			documentId: resultId,
			engine: this.engine,
		}).subscribe({
			next: (res) => {
				return
			},
			error: (err) => {
				return
			},
		});
	}

	triggerSearch(searchValue: string, startAt: number = 1, ignoreLoading: boolean = false): void {
		if (!ignoreLoading) { this.loading = true }
		this.backendService.search({
			query: searchValue,
			engine: this.engine,
			startPage: startAt,
			pageSize: this.searchSize,
		}).subscribe({
			next: (res) => {
				this.loading = false
				if (startAt > 1) {
					this.searchResults = this.searchResults.concat(res.results)
				} else {
					this.searchResults = res.results
				}
				this.searchPageCurrent = res.meta.page.current
				this.searchSize = res.meta.page.size
				this.searchResultsTotal = res.meta.page.total_results
				return
			},
			error: (err) => {
				this.loading = false
				this.error = err.message
				console.warn(err)
				return
			},
		});
	}

	moreResults() {
		this.triggerSearch(this.searchValue, (this.searchPageCurrent + 1), true)
	}

	groupSearchItems = (item) => {
		switch (item.type.raw) {
			case 'community':
				return 'Communities'
			case 'event':
				return 'Events'
			default:
				return item.type.raw.charAt(0).toUpperCase() + item.type.raw.slice(1);
		}
	}


}
