<div class="mb-3 text-center" *ngIf="(selectedSeats.length)>=maxSelectable"><strong>You can only select up to {{maxSelectable}} tickets.</strong></div>
<div class="card noselect">
  <ng-container *ngIf="showChart; else loading">
    <div class="btn-group">
      <a (click)="toggleFloor('main')" class="btn btn-primary" [ngClass]="{'active': selectedView === 'main'}"
        mdbRipple>
        Main Floor
      </a>
      <a (click)="toggleFloor('balcony')" class="btn btn-primary" [ngClass]="{'active': selectedView === 'balcony'}"
        mdbRipple>
        Balcony
      </a>
    </div>
    <div id="seatMapChart" style="height: 500px;"></div>
  </ng-container>
  <ng-template #loading>
    <app-loading></app-loading>
  </ng-template>

</div>