import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  constructor(private http: HttpClient) {}

  // Utils
  submitNotFoundError(body: Object): Observable<any> {
    return this.http.post<any>(`${environment.backendUrl}/submit-error`, body);
  }

  // Search
  search(body: object): Observable<any> {
    return this.http.post<any>(`${environment.backendUrl}/search`, body);
  }
  clickResult(body: object): Observable<any> {
    return this.http.post<any>(`${environment.backendUrl}/click-result`, body);
  }

  // User Management
  getUserCommunities(): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/user-communities`,);
  }
  getMyStudents(): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/user-linked-students`,);
  }
  getStudentDetails(id): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/user-linked-student/${id}`,);
  }
  
  
  // Communities
  getCommunityDetails(communityId: string): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/community-details/${communityId}`,);
  }
  getCommunityAccess(communityId: string): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/community-access/${communityId}`,);
  }
  getCommunityFundraisers(communityId: string): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/community-fundraisers/${communityId}`,);
  }
  getCommunityEvents(communityId: string, body: Object): Observable<any> {
    return this.http.post<any>(`${environment.backendUrl}/community-events/${communityId}`, body);
  }
  getCommunityEvent(communityId: string, eventId: string): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/community-events/${communityId}/${eventId}`,);
  }
  getCommunityEventSeatmap(communityId: string, eventId: string): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/community-events/${communityId}/${eventId}/seatmap`,);
  }
  reserveCommunityEventSeats(communityId: string, eventId: string, body: Object): Observable<any> {
    return this.http.post<any>(`${environment.backendUrl}/community-events/${communityId}/${eventId}/reserve-seats`, body);
  }
  redeemCompEventTickets(communityId: string, eventId: string, body: any): Observable<any> {
    return this.http.post<any>(`${environment.backendUrl}/community-events/${communityId}/${eventId}/redeem-seats`, body);
  }
  
  
  // Videos
  getVideoDetails(videoId: string): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/video-details/${videoId}`,);
  }

  // Ticketing
  getMyTickets(): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/passes/my-passes`,);
  }
  getMyEventTickets(communityId: string, eventId: string): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/passes/my-passes/${communityId}/${eventId}`,);
  }
  getTicketDetailsWithAccessCode(communityId: string, eventId: string, orderId: string, accessCode: string): Observable<any> {
    console.log('fetching details for:',communityId, eventId, orderId, accessCode)
    return this.http.get<any>(`${environment.backendUrl}/passes/access-code/${communityId}/${eventId}/${orderId}/${accessCode}`);
  }
  linkMobileWallet(communityId: string, eventId: string): Observable<any> {
    return this.http.post<any>(`${environment.backendUrl}/passes/link-mobile-wallet/${communityId}/${eventId}`, {});
  }
  getTicketClasses(communityId: string): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/passes/ticket-classes/${communityId}`,);
  }
  validateTicket(eventId: string, ticketId: string): Observable<any> {
    return this.http.post<any>(`${environment.backendUrl}/passes/validate/${eventId}`, {ticketId: ticketId});
  }
  forceValidateTicket(eventId: string, ticketId: string): Observable<any> {
    return this.http.post<any>(`${environment.backendUrl}/passes/force-validate/${eventId}`, {ticketId: ticketId});
  }

  // Payments  
  createEventTicketPaymentIntent(communityId: string, eventId: string, body: any): Observable<any> {
    return this.http.post<any>(`${environment.backendUrl}/payments/create-intent/event-tickets/${communityId}/${eventId}`, body);
  }
  getCommunityTransactions(communityId: string, body: any): Observable<any> {
    return this.http.post<any>(`${environment.backendUrl}/payments/communities/${communityId}`, body);
  }
  getCommunityTransaction(communityId: string, transactionId: string): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/payments/communities/${communityId}/${transactionId}`);
  }
  getPaymentDetailsWithAccessCode(paymentId: string, accessCode: string): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/payments/${paymentId}/details/${accessCode}`);
  }

  
}
