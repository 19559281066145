import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  loading: boolean = false;
  showUserSearch: boolean = true;
  selectedUserDetails
  overviewDetails
  error
  constructor(
  ) { }

  ngOnInit(): void {
  }



  openLink($event) {
    this.selectedUserDetails = $event.result
    this.showUserSearch = false
  }

  resetSearch() {
    this.selectedUserDetails = null
    this.showUserSearch = true
  }

}
