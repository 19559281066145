<ng-container *ngIf="!loading; else displaySkeleton">
  <div *ngIf="currentView==='eventList'" [@fadeInEnter]>
    <div style="margin-bottom: 20px">
      <div>
        <div style="text-align:right;">
          <div mdbDropdown #viewDrop class="dropdown" [animation]="false">
            <button class="btn btn-grey btn-rounded dropdown-toggle me-1" type="button" id="dropdownMenuButton"
              aria-expanded="false" mdbDropdownToggle>
              <span><i class="fas fa-cog me-2"></i>Actions</span>
            </button>
            <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
              <li (click)="createEvent()"><a class="dropdown-item pointer"><i class="fas fa-plus me-2"></i>Create
                  Event</a>
              </li>
              <li (click)="getEvents()"><a class="dropdown-item pointer"><i class="fas fa-sync-alt me-2"></i>Refresh</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div *ngFor="let item of events; let i = index" class="col-xs-12 col-md-6 col-lg-4 col-xl-4">
        <div class="card mb-4">
          <div class="card-body">
            <h5 class="card-title">{{item.title}}</h5>
            <p class="card-text" [innerHTML]="item.body | htmlTruncate:100"></p>
            <div class="card-text">
              <div class="d-flex align-items-center">
                <!-- <img referrerPolicy="no-referrer" [src]="item.editors[0].profilePic" alt=""
                  style="width: 40px; height: 40px;" class="rounded-circle" /> -->
                <div class="">
                  <p class="lh-1 fw-bold mb-1">{{item.startTime | date:'fullDate'}}</p>
                  <p class="lh-1 text-muted mb-0">
                    {{item.startTime | date:'shortTime'}}
                  </p>
                </div>
              </div>
            </div>
            <br class="noselect">
            <p class="card-text">
              <button (click)="viewDetails(item)" type="button" class="btn btn-primary btn-rounded">Details</button>
            </p>
          </div>
        </div>
      </div>
      <div *ngIf="events.length===0" class="row">
        <app-no-results [message]="'No events found for ' + communityDetails.name + '.'"></app-no-results>
      </div>
    </div>
  </div>
  <div *ngIf="currentView==='eventView'" [@fadeInEnter]>
    <div class="d-flex justify-content-between" style="margin-bottom: 20px">
      <div>
        <div class="d-flex align-items-center">
          <div>
            <div class="d-flex align-items-center">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li (click)="returnToList()" class="breadcrumb-item pointer"><a>All Events</a></li>
                  <li *ngIf="!loadingEvent" class="breadcrumb-item active" aria-current="page">{{postDetails.title}}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="d-flex justify-content-end align-items-center">
          <div mdbDropdown #viewDrop class="dropdown" [animation]="false">
            <button class="btn btn-grey btn-rounded dropdown-toggle me-1" type="button" id="dropdownMenuButton"
              aria-expanded="false" mdbDropdownToggle>
              <span><i class="fas fa-cog me-2"></i>Actions</span>
            </button>
            <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
              <li (click)="editEvent()"><a class="dropdown-item pointer"><i class="fas fa-edit me-2"></i>Edit Event</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!loadingEvent; else displayEventLoading">
      <div class="row">
        <div class="col-sm-12 col-md-7 col-lg-8 col-xl-8">
          <app-slider [sliders]="postDetails.sliders"></app-slider>
          <div class="card">
            <div class="card-body">
              <h5>{{postDetails.name}}</h5>
              <p [innerHTML]="postDetails.body"></p>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-5 col-lg-4 col-xl-4">
          <div>

            <ng-select [virtualScroll]="false" mdbInput [(ngModel)]="postDetails.id" (open)="displaySelectDropdown=true"
              (close)="displaySelectDropdown=false" (change)="getEvent($event.id)" [items]="seriesEvents"
              [multiple]="false" placeholder="Selected Event" bindLabel="eventSeriesName" bindValue="id"
              appearance="outline" [closeOnSelect]="true" [clearable]="false" [searchable]="false"
              notFoundText="No events found" autocomplete="off" required>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <div [ngOptionHighlight]="search" class="lh-1 pt-1 pb-1">
                  <div class="">{{item.eventSeriesName}}</div>
                  <div class="text-muted small text-wrap">
                    {{item.startTime | date:'fullDate'}} at {{item.startTime | date:'shortTime'}}
                  </div>
                </div>
              </ng-template>
            </ng-select>
            <div *ngIf="displaySelectDropdown" [style.height]="calculateHeight()"></div>

            <div class="small mt-2">Date</div>
            <p>{{postDetails.startTime | date:'fullDate'}}</p>


            <div class="row">
              <div class="col-6">
                <div class="small">Doors Open</div>
                <p>{{postDetails.doorsOpenTime | date:'shortTime'}}</p>
              </div>
              <div class="col-6 text-end">
                <div class="small">Start Time</div>
                <p>{{postDetails.startTime | date:'shortTime'}}</p>
              </div>
            </div>

            <!-- {{venueDetails | json}} -->
          </div>
          <ng-container *ngIf="postDetails.salesStart && enableTicketPurchase(postDetails.salesStart); else buyTickets">
            <div class="text-center text-muted small">Get ready! Tickets go live in</div>
            <div class="row">
              <mdb-countdown [countdown]="postDetails.salesStart" [countdownTextStyle]="'bg-primary full-width'"
                [countdownLabelStyle]="'text-light bg-dark'" [countdownLabelDays]="'Days'"
                [countdownLabelHours]="'Hours'" [countdownLabelMinutes]="'Minutes'" [countdownLabelSeconds]="'Seconds'"
                [countdownUnits]="['days', 'hours', 'minutes', 'seconds']"></mdb-countdown>
            </div>
            <div class="text-center text-muted">
              {{postDetails.salesStart | date:'fullDate'}} at {{postDetails.salesStart | date:'shortTime'}}
            </div>
          </ng-container>
          <ng-template #buyTickets>
            <ng-container *ngIf="loggedIn; else notLoggedIn">
              <div *ngIf="postDetails.compAccess.entitlements>0" (click)="viewSeatmap('redeem')"
                class="btn btn-primary btn-rounded block btn-block">Redeem {{postDetails.compAccess.entitlements}} Free
                Ticket<span *ngIf="postDetails.compAccess.entitlements>1">s</span>
                <div *ngIf="postDetails.compAccess.redeemedEntitlements>0" class="small">
                  ({{postDetails.compAccess.redeemedEntitlements}} Already Redeemed)</div>
              </div>
              <div (click)="viewSeatmap('purchase')" class="btn btn-primary btn-rounded block btn-block">Buy Tickets
              </div>
            </ng-container>
            <ng-template #notLoggedIn>
              <div class="text-center text-muted small">Tickets available now! (login required)</div>
              <div (click)="login()" class="btn btn-primary btn-rounded block btn-block">Buy Tickets</div>
            </ng-template>
          </ng-template>
        </div>
      </div>
      <div class="mt-4">
        <app-view-map height="350px" width="100%" [zoom]="17" [lat]="venueDetails.lat" [lng]="venueDetails.lng">
        </app-view-map>
        <div class="card">
          <div class="card-body text-start">
            <div class="small mt-0">Venue Details</div>
            <h6 class="mt-0 pt-0 mb-0 pb-0">{{venueDetails.name}}</h6>
            <p class="mt-0 pt-0 mb-0 pb-0"><i class="fas fa-map-marker-alt"></i> {{venueDetails.address}}</p>
            <p class="mt-0 pt-0 mb-0 pb-0"><i class="fas fa-comment"></i> {{venueDetails.notes}}</p>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #displayEventLoading>
      <ng-container *ngIf="!errorEvent; else displayErrorEvent">
        <app-loading></app-loading>
      </ng-container>
      <ng-template #displayErrorEvent>
        <app-https-error [error]="errorEvent"></app-https-error>
      </ng-template>
    </ng-template>
  </div>
  <div *ngIf="currentView==='seatMap'">
    <div class="d-flex justify-content-between" style="margin-bottom: 20px">
      <div>
        <div class="d-flex align-items-center">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li (click)="returnToList()" class="breadcrumb-item pointer"><a>All Events</a></li>
              <li (click)="viewDetails(postDetails)" class="breadcrumb-item pointer"><a>{{postDetails.title}}</a></li>
              <li class="breadcrumb-item active" aria-current="page">Select Seats</li>
            </ol>
          </nav>
        </div>
      </div>
      <div>
        <div class="d-flex justify-content-end align-items-center">
          <button (click)="checkout()" [disabled]="selectedSeats.length === 0" type="button"
            class="btn btn-primary btn-rounded">
            <span *ngIf="purchaseType==='purchase'">Purchase</span>
            <span *ngIf="purchaseType==='redeem'">Redeem</span>
            <span *ngIf="selectedSeats.length !== 0">-
              {{selectedSeats.length}}</span>
            <div class="small text-small">Select up to {{purchaseLimit}}</div>
          </button>
        </div>
      </div>
    </div>
    <ng-container *ngIf="!error; else displayError">
      <app-seatmap-view [maxSelectable]="purchaseLimit" [backgroundOffsetX]="1" [backgroundOffsetY]="1" [seatData]="seatData"
        [backgroundImg]="seatmapBackgroundImg" [isLoadingSeatData]="isLoadingSeatData"
        (selectSeat)="selectSeat($event)" (outputError)="errorToast($event)" [selectedSeats]="selectedSeats"></app-seatmap-view>

    </ng-container>
    <ng-template #displayError>
      <app-https-error [error]="error"></app-https-error>

    </ng-template>
  </div>
</ng-container>
<ng-template #displaySkeleton>
  <ng-container *ngIf="!error; else displayError">
    <div class="row">
      <div *ngFor="let item of [].constructor(6); let i = index" class="col-xs-12 col-md-6 col-lg-4 col-xl-4">
        <app-list-item-loading></app-list-item-loading><br>
      </div>
    </div>
  </ng-container>
  <ng-template #displayError>
    <app-https-error [error]="error"></app-https-error>
  </ng-template>
</ng-template>