<div class="card search-container">
  <div
    [ngClass]="{'searchcontainerwhite': searchBoxFocused, 'searchcontainer': !searchBoxFocused, 'd-flex': true, 'justify-content-start': true, 'align-items-center': true}"
    style="position: relative;">
    <div style="position: relative; width: 100%;">
      <i class="fas fa-search search-icon"
        style="position: absolute; top: 50%; left: 10px; transform: translateY(-50%); pointer-events: none; margin-left: 3px;"></i>
      <ng-select class="homepage-search"
        style="width: 100%; padding: 0px 9px 8.5px 43px; margin-bottom: 8px; margin-right: 4px; border: none;"
        [validateSuccess]="false" [mdbValidate] mdbInput (open)="displaySelectDropdown=true"
        (close)="displaySelectDropdown=false" [items]="searchResults" [groupBy]="groupSearchItems" [multiple]="false"
        [virtualScroll]="false" appearance="outline" [closeOnSelect]="true" [clearable]="true"
        [typeahead]="searchInput$" [isOpen]="isOpen" [loading]="loading" (focus)="onSearchFocus(true)"
        (focusout)="onSearchFocus(false)" (change)="clickItem(searchValue, $event)" [appendTo]="'body'" [autoPosition]="true" required>
        <ng-template ng-option-tmp let-item="item" class="mb-0 pb-0" let-index="index" >
          <div [ngOptionHighlight]="search" class="text-wrap mb-0 pb-0"
            style="line-height: 0rem;">
            <ng-container [ngSwitch]="item.type.raw">
              <div *ngSwitchCase="'community'">
                <h6 [innerHtml]="item.name.snippet" class="result-item" style="padding-top: 5px; padding-bottom: 0px;"></h6>
                <!-- <div style="margin-bottom: 5px;"></div> -->
              </div>
              <div *ngSwitchCase="'event'">
                <h6 [innerHtml]="item.name.snippet" class="result-item" style="padding-top: 5px; padding-bottom: 0px">
                </h6>
                <div *ngIf="item.description && item.description.snippet" [innerHtml]="item.description.snippet"
                  style="line-height: 1.25rem;" class="text-wrap result-item-description text-small mb-0 pb-0"></div>
                <div style="margin-bottom: 5px;"></div>
              </div>
              <div *ngSwitchDefault></div>
            </ng-container>
          </div>
        </ng-template>
        <ng-template ng-typetosearch-tmp>
          <div class="ng-option disabled">Start typing to search...</div>
        </ng-template>
        <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
          <div class="ng-option disabled">
            <ng-container *ngIf="searchValue && searchValue.length > 2; else typeMoreToSearch">
              <div>
                No results found for <em>"{{searchValue}}"</em>
              </div>
            </ng-container>
            <ng-template #typeMoreToSearch>
              Enter at least 3 characters to search
            </ng-template>
          </div>
        </ng-template>
        <ng-template ng-loadingtext-tmp let-searchTerm="searchTerm">
          <div class="ng-option disabled">Fetching data for "{{ searchTerm }}"</div>
        </ng-template>
        <ng-template *ngIf="searchResultsTotal > 0;" ng-footer-tmp>
          <ng-container>
            {{ searchResultsTotal }} results found for <em>"{{searchValue}}"</em>
          </ng-container>
        </ng-template>
      </ng-select>
    </div>
  </div>
</div>