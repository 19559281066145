<section class="">
  <ng-container *ngIf="!loading; else displayLoading">
    <app-community-header [communityDetails]="communityDetails"></app-community-header>
    <div class="container text-center text-md-start mt-5">
      <div class="row mt-3">
        <div class="col-md-12 col-lg-12 col-xl-12 mx-auto mb-12">
          <mdb-tabs *ngIf="communityDetails.access===true" (activeTabChange)="onTabChange($event)" #tabs>
            <!-- <mdb-tab title="Welcome">
              <ng-template mdbTabContent>
                <br class="noselect">
                <app-community-member-home [communityDetails]="communityDetails"></app-community-member-home>
              </ng-template>
            </mdb-tab> -->
            <mdb-tab title="Events" *ngIf="communityDetails.enableEvents">
              <ng-template mdbTabContent>
                <br class="noselect">
                <app-community-events-home [communityDetails]="communityDetails"></app-community-events-home>
              </ng-template>
            </mdb-tab>
            <mdb-tab title="Videos">
              <ng-template mdbTabContent>
                <br class="noselect">
                <ng-container *ngIf="!communityDetails.admin; else displayAdminVideo">
                  <app-community-videos [communityDetails]="communityDetails"></app-community-videos>
                </ng-container>
                <ng-template #displayAdminVideo>
                  <app-community-manage-videos [communityDetails]="communityDetails"></app-community-manage-videos>
                </ng-template>
              </ng-template>
            </mdb-tab>
            <mdb-tab title="Fundraisers" *ngIf="environmentName!=='production'">
              <ng-template mdbTabContent>
                <br class="noselect">
                <app-community-fundraisers-home [communityDetails]="communityDetails"></app-community-fundraisers-home>
              </ng-template>
            </mdb-tab>
            <mdb-tab *ngIf="communityDetails.admin===true" title="Admin">
              <ng-template mdbTabTitle>
                <span><i class="fas fa-lg fa-cog me-2"></i> Manage</span>
              </ng-template>
            </mdb-tab>
          </mdb-tabs>
          <app-join-community [communityDetails]="communityDetails"
            *ngIf="communityDetails.access===false"></app-join-community>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #displayLoading>
    <div class="container text-center text-md-start mt-5">
      <div class="row mt-3">
        <div class="col-md-12 col-lg-12 col-xl-12 mx-auto mb-12">
          <ng-container *ngIf="!error; else displayError">
            <app-loading></app-loading>
          </ng-container>
          <ng-template #displayError>
            <app-https-error [error]="error"></app-https-error>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-template>
</section>